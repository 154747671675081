import Immutable from 'immutable';

let initState = Immutable.fromJS({
  networkErrors: {
    error: false,
    count: 0,
    status: null,
  },
});

export default function errorReducer(state = initState, action) {
  switch (action.type) {
    case 'ERROR_SET': {
      const status = action.result && action.result.status ? action.result.status : null;
      const currentStatus = state.getIn(['networkErrors', 'status']);
      // 500+ errors are sticky, takes precedence over less critical errors
      const nextStatus = currentStatus >= 500 ? currentStatus : status;

      return state
        .setIn(['networkErrors', 'error'], true)
        .setIn(['networkErrors', 'count'], state.getIn(['networkErrors', 'count']) + 1)
        .setIn(['networkErrors', 'status'], nextStatus);
    }

    case 'ERROR_RESET': {
      const currentStatus = state.getIn(['networkErrors', 'status']);

      // 500 are sticky and cannot be reset
      if (currentStatus >= 500)
        return;

      return state
        .setIn(['networkErrors', 'error'], false)
        .setIn(['networkErrors', 'count'], 0)
        .setIn(['networkErrors', 'status'], null);
    }
    default:
      return state;
  }
}
