import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions';
import { i18n } from 'utils/i18nUtils';
import ReactModal from 'react-modal';
import CloseIcon from 'common/assets/icons/CloseIcon';
import Register from 'customer/Auth/components/Register/Register';
import ForgotPassword from 'customer/Auth/components/ForgotPassword/ForgotPassword';
import AddLibraryCard from 'customer/UserSettings/components/LibraryCards/AddLibraryCard';
import { updateUserProfile, UPDATED_USER_PROFILE } from '../../../UserSettings/userSettingsActions';
import { subscribeToNewsletterPopup } from '../../appActions';
import './Modal.scss';
import Newsletter from '../NewsletterPopup/Newsletter';
import Login from 'customer/Auth/components/Login/Login';
import Button from 'common/components/Button/Button';

ReactModal.setAppElement('#app');

class Modal extends Component {
  constructor(props) {
    super(props);

    this.content = {
      login: { title: () => i18n('LOG_IN'), content: <Login /> },
      newsletter: {
        title: () => {
          i18n('NEWSLETTER_INFO');
        },
        content: <Newsletter setNewsletterSubscription={this.setNewsletterSubscription} />,
      },
      register: {
        title: () => i18n('REGISTER'),
        content: (
          <Register
            showLoginLink
            infoText={i18n('REGISTER_INFO')}
            submitButtonText={i18n('REGISTER')}
            successText={i18n('ACCOUNT_CREATED')}
            onDone={props.closeModal}
          />
        ),
      },
      forgotPassword: { title: () => i18n('FORGOT_PASSWORD'), content: <ForgotPassword /> },
      addLibraryCard: { title: () => i18n('ADD_LIBRARY_CARD'), content: <AddLibraryCard /> },
      deleteConfirmation: {
        title: () => i18n('DELETE_ACCOUNT'),
        content: (
          <div className="delete-confirmation-content">
            <p>{i18n('DELETE_ACCOUNT_CONFIRMATION')}</p>
            <div className="confirmation-buttons">
              <Button
                className="confirmation-button"
                modifier="danger"
                onClick={() => {
                  this.props.handleConfirmDelete?.();
                  this.props.closeModal();
                }}
              >
                {i18n('YES')}
              </Button>
              <Button
                className="button-cancel"
                variant="secondary"
                onClick={this.props.closeModal}
              >
                {i18n('NO')}
              </Button>
            </div>
          </div>
        ),
      },
    };
  }

  handleClose = () => {
    const { modal, closeModal, addInactiveSubscriber, userEmail, newsletterSubscriptionStatus } = this.props;

    if (modal === 'newsletter' && newsletterSubscriptionStatus !== 'success') {
      this.props.subscribeToNewsletterPopup(false);
    }

    closeModal();
  };

  render() {
    if (!this.props.modal) {
      return null;
    }

    return (
      <ReactModal
        overlayClassName="modal-overlay"
        className="modal"
        isOpen={!!this.props.modal}
        onRequestClose={this.handleClose}
        contentLabel={this.content[this.props.modal].title()}
        shouldCloseOnOverlayClick={true}
      >
        <div className="title-bar">
          <p className="title">{this.content[this.props.modal].title()}</p>
          <div className="close-icon" onClick={this.handleClose}>
            <CloseIcon />
          </div>
        </div>
        {this.content[this.props.modal].content}
      </ReactModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modal: state.get('modalReducers').modal,
    handleConfirmDelete: state.get('modalReducers').onConfirmDelete,
    userData: state.getIn(['authReducers', 'userData']),
    newsletterSubscriptionStatus: state.getIn(['appReducers', 'newsletterSubscriptionStatus']),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  updateUserProfile,
  subscribeToNewsletterPopup,
})(Modal);
