import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll } from 'react-router-scroll';
import configureStore from './store';
import cookie from 'react-cookie';
import { createSuperAgent } from './utils/requestUtils';
import { fetchTranslations, i18nRedirect } from './utils/i18nUtils';
import { initImdbTemporaryStorage } from './utils/imdbUtils';
import { setSessionIdCookie, setCsrfTokenCookie } from 'utils/cookieUtils';
import { IS_LIBRARIAN_PORTAL, IS_CUSTOMER_PORTAL, PORTAL_NAME } from 'common/constants';
import { setError } from 'common/err/errorActions';

import { selectLocationState } from 'common/selectors';

// Set up the router, wrapping all Routes in the App component
import App from 'customer/App';
import LibApp from 'librarian/LibApp';
import createRoutes from './routes';

// Import the CSS reset, which HtmlWebpackPlugin transfers to the build folder
import 'sanitize.css/sanitize.css';
import { array } from 'prop-types';

// ----- REDIRECTIONS ----- //

// First possible redirection: user tried to access herokuapp domain for production site
if (window.location.hostname === 'forlagett.herokuapp.com') {
  window.location = 'https://www.cineasterna.com';
}

// Second possible redirection: redirect users from http to https
if (process.env.NODE_ENV !== 'development' && window.location.protocol && window.location.protocol !== 'https:') {
  window.location = 'https://' + window.location.hostname + window.location.pathname;
}

// Third possible redirection: redirect users from bad i18n URLs
i18nRedirect(IS_CUSTOMER_PORTAL, IS_LIBRARIAN_PORTAL);

// ----- END REDIRECTIONS ----- //

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: selectLocationState(),
});

const rootRoute = {
  component: IS_LIBRARIAN_PORTAL ? LibApp : App,
  childRoutes: createRoutes(store),
};

// ---- Bootstrap ---- //

// in case localStorage is not supported, have dummy functions instead to safely use it without errors
window.localStorageEnabled = typeof localStorage !== 'undefined';
if (!window.localStorageEnabled) {
  window.localStorage = {
    getItem: () => undefined,
    setItem: () => undefined,
    key: () => undefined,
    clear: () => undefined,
    removeItem: () => undefined,
    length: 0,
  };
}

// init temporary storage for IMDB stuff
initImdbTemporaryStorage();

// ---- Rendering ---- //

const startRenderingProcess = () => {
  // before rendering make request to fetch translations and have them in a global variable, because
  // it's really a convenient way to then use translations anywhere, no need for reducers, woohoo!
  fetchTranslations(() => {
    ReactDOM.render(
      <Provider store={store}>
        <div>
          <Router
            history={history}
            routes={rootRoute}
            render={
              // Scroll to top when going to a new page, imitating default browser behaviour
              applyRouterMiddleware(useScroll())
            }
          />
        </div>
      </Provider>,
      document.getElementById('app')
    );
  });
};

// ---- Session and CSRF token management ---- //

export let initialLoginData = null;

const cookieSessionId = cookie.load(PORTAL_NAME + '_sessionid');
const cookieCsrftoken = cookie.load(PORTAL_NAME + '_csrftoken');

if (!cookieSessionId || !cookieCsrftoken) {
  createSuperAgent({ method: 'post', path: '/init_portal_session' }).end((err, res) => {
    if (err) {
        store.dispatch(setError(err, undefined, res))
    }
    if (res && res.body && res.body.success && res.body.sessionid && res.body.csrftoken) {
      setSessionIdCookie(res.body.sessionid);
      setCsrfTokenCookie(res.body.csrftoken, false);
    }
    startRenderingProcess();
  });
} else {
  // try to renew the CSRF token - at the same time we know if the session data on backend is existing/valid
  createSuperAgent({ method: 'post', path: '/renew_portal_csrftoken' }).end((err, res) => {
    if (res && res.body && res.body.success && res.body.new_csrftoken) {
      initialLoginData = res.body.login_data;
      setCsrfTokenCookie(res.body.new_csrftoken); // all good, reset csrftoken cookie
      startRenderingProcess();
    } else {
      // no session existing/valid, hence remove cookies, to be set correctly after reload
      setSessionIdCookie(null);
      setCsrfTokenCookie(null);
      window.location.reload();
    }
  });
}
