import React, { Component } from 'react';
import { connect } from 'react-redux';
import { i18n } from 'utils/i18nUtils';
import { showNewsletterModal } from 'customer/App/components/Modal/modalActions';
import { subscribeToNewsletterPopup } from '../../appActions';
import Button from 'common/components/Button/Button';
import Input from 'common/components/Input/Input';
import EmailIcon from 'common/assets/icons/EmailIcon';
import './newsletter.scss';

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.userEmail || '',
      agree: false,
    };

    this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
    this.handleOnChangeAgree = this.handleOnChangeAgree.bind(this);
    this.subscribe = this.subscribe.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userEmail !== prevProps.userEmail && !this.state.email) {
      this.setState({ email: this.props.userEmail });
    }
  }

  handleOnChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleOnChangeAgree(e) {
    this.setState({ agree: e.target.checked });
  }

  subscribe(e) {
    e.preventDefault();
    this.props.subscribeToNewsletterPopup(true);
  }

  renderSuccessMessage() {
    return (
      <div className="newsletter-success">
        <p>{i18n('NEWSLETTER_SUBSCRIPTION_SUCCESS')}</p>
      </div>
    );
  }

  renderForm() {
    return (
      <form className="newsletter-form" onSubmit={this.subscribe} noValidate>
        <div className="newsletter-content">
          <p className="newsletter-info">{i18n('NEWSLETTER_INFO')}</p>
        </div>
        <Button 
          className="submit-button" 
          type="submit" 
          disabled={false}
        >
          {i18n('SUBSCRIBE_BUTTON')}
        </Button>
      </form>
    );
  }

  render() {
    return (
      <div className="newsletter">
        {this.props.newsletterSubscriptionStatus === 'success' 
          ? this.renderSuccessMessage() 
          : this.renderForm()
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.getIn(['authReducers', 'userData', 'email']),
    newsletterSubscriptionStatus: state.getIn(['appReducers', 'newsletterSubscriptionStatus'])
  };
}

export default connect(mapStateToProps, {
  showNewsletterModal,
  subscribeToNewsletterPopup,
})(Newsletter);