import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentLanguage, getCurrentCountry } from 'utils/i18nUtils';
import { setCsrfTokenCookie } from 'utils/cookieUtils';
import { closeModal } from 'customer/App/components/Modal/modalActions';
import { loadLibrary } from 'customer/App/appActions';

export const FETCHED_USER_DATA = Symbol('FETCHED_USER_DATA');
export const SET_USER_DATA = Symbol('SET_USER_DATA');
export const LOGIN_ATTEMPTED = Symbol('LOGIN_ATTEMPTED');
export const REGISTER_ATTEMPTED = Symbol('REGISTER_ATTEMPTED');
export const LOGOUT_ATTEMPTED = Symbol('LOGOUT_ATTEMPTED');
export const PASSWORD_RESET_REQUESTED = Symbol('PASSWORD_RESET_REQUESTED');
export const NEWSLETTER_SUBSCRIPTION_SUCCEEDED = Symbol('NEWSLETTER_SUBSCRIPTION_SUCCEEDED');
export const NEWSLETTER_SUBSCRIPTION_FAILED = Symbol('NEWSLETTER_SUBSCRIPTION_FAILED');

export function fetchUserData() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/user/user/get_user_data',
      successType: FETCHED_USER_DATA,
    },
  };
}

export function setUserData(userData) {
  return {
    userData: userData,
    type: SET_USER_DATA,
  };
}

// user login, nothing to do with customer loan credentials
export function userLogin(username, password) {
  return (dispatch, getState) => {
    dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/user/login',
        body: {
          username,
          password,
        },
        successType: LOGIN_ATTEMPTED,
      },
    }).then((response) => {
      dispatch(setUserData(response[0].user));
      setCsrfTokenCookie(response[0].new_csrftoken);
      dispatch(loadLibrary(getState().get('appReducers').get('library').get('id')));
      dispatch(closeModal());
    });
  };
}

export function userRegister(
  username,
  email,
  password,
  logUserInAfterRegister,
  firstName,
  lastName,
  subscribeNewsletter
) {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/user/register',
        body: {
          username,
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          log_user_in_after_register: logUserInAfterRegister,
          country_iso: getCurrentCountry(),
          subscribe_newsletter: subscribeNewsletter,
        },
        successType: REGISTER_ATTEMPTED,
      },
    })
      .then(() => dispatch(fetchUserData()))
      .catch((error) => {
        console.error('Error during or after registration:', error);
      });
  };
}

export function userLogout() {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/user/logout',
      successType: LOGOUT_ATTEMPTED,
    },
  };
}

export function requestPasswordReset(email) {
  const language = getCurrentLanguage();
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/password_recovery/recover_with_email',
      body: {
        email,
        language,
      },
      successType: PASSWORD_RESET_REQUESTED,
    },
  };
}
