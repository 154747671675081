import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentLanguage, getCurrentCountry } from 'utils/i18nUtils';
import { showNewsletterModal } from 'customer/App/components/Modal/modalActions';

export const NEWSLETTER_SUBSCRIPTION_SUCCEEDED = Symbol('NEWSLETTER_SUBSCRIPTION_SUCCEEDED');
export const NEWSLETTER_SUBSCRIPTION_FAILED = Symbol('NEWSLETTER_SUBSCRIPTION_FAILED');
export const LOADED_LIBRARY = Symbol('LOADED_LIBRARY');
export const LOADED_CONFIGURATION = Symbol('LOADED_CONFIGURATION');
export const LOADED_COUNTRY_CONFIGURATION = Symbol('LOADED_COUNTRY_CONFIGURATION');
export const UPDATE_NEWSLETTER_DECISION = Symbol('UPDATE_NEWSLETTER_DECISION');
export const NEWSLETTER_SHOWN_CHECK = Symbol('NEWSLETTER_SHOWN_CHECK');

export function loadLibrary(libraryId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/library/get_library',
      query: {
        library_id: libraryId,
      },
      successType: LOADED_LIBRARY,
    },
  };
}

export function loadConfiguration() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/configuration/configuration/get_config',
      query: {
        site_lang: getCurrentLanguage(),
      },
      successType: LOADED_CONFIGURATION,
    },
  };
}

export function loadCountryConfiguration() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/configuration/country_configuration/get_country_config',
      query: {
        country_code: getCurrentCountry(),
      },
      successType: LOADED_COUNTRY_CONFIGURATION,
    },
  };
}

export function checkNewsletterShown() {
  return (dispatch, getState) => {
    const isNewlyRegistered = getState().getIn(['authReducers', 'isNewlyRegistered']);
    const userEmail = getState().getIn(['authReducers', 'userData', 'email']);

    if (isNewlyRegistered || !userEmail) {
      return Promise.resolve();
    }

    return dispatch({
      [CALL_API]: {
        method: 'get',
        path: '/subscriptions/subscriptions/check_subscriber_exists',
        successType: NEWSLETTER_SHOWN_CHECK,
      },
    })
      .then((response) => {
        const data = response[0];
        const exists = data.exists;

        if (!exists) {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(dispatch(showNewsletterModal()));
            }, 5000);
          });
        }
      })
      .catch((error) => {
        console.error('Error checking subscriber status:', error);
        return false;
      });
  };
}

export function subscribeToNewsletterPopup(optIn) {
  const country_iso = getCurrentCountry().toUpperCase();
  return {
    [CALL_API]: {
      method: 'post',
      path: '/subscriptions/subscriptions/subscribe',
      body: { opt_in: optIn, country_iso },
      successType: NEWSLETTER_SUBSCRIPTION_SUCCEEDED,
      failureType: NEWSLETTER_SUBSCRIPTION_FAILED,
    },
  };
}
