import * as ActionType from './userSettingsActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  changedPasswordResult: {},
  passwordRecoveryResult: {},
  updatedUserProfileResult: {},
  addedLibraryCardResult: {},
  removedLibraryCardResult: {},
});

export default function userSettingsReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATED_USER_PROFILE:
      return state.merge({ updatedUserProfileResult: action.response });

    case ActionType.CHANGED_PASSWORD:
      return state.merge({ changedPasswordResult: action.response });

    case ActionType.ASKED_FOR_PASSWORD_RECOVERY:
      return state.merge({ passwordRecoveryResult: action.response });

    case ActionType.ADDED_INVENTORY:
      return state.merge({ addedLibraryCardResult: action.response });

    case ActionType.REMOVED_INVENTORY:
      return state.merge({ removedLibraryCardResult: action.response });

    case ActionType.SOFT_DELETED_ACCOUNT:
      return state.merge({ softDeletedAccountResults: action.response });

    default:
      return state;
  }
}
