import { userLogout } from 'customer/Auth/authActions';
import { push } from 'react-router-redux';
import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentLanguage } from 'utils/i18nUtils';
import { fetchUserData, setUserData } from 'customer/Auth/authActions';
import { closeModal } from 'customer/App/components/Modal/modalActions';
import { showNewsletterModal } from 'customer/App/components/Modal/modalActions';
import { getCurrentCountry } from 'utils/i18nUtils';
export const SOFT_DELETED_ACCOUNT = Symbol('SOFT_DELETED_ACCOUNT');

export const ASKED_FOR_PASSWORD_RECOVERY = Symbol('ASKED_FOR_PASSWORD_RECOVERY');
export const UPDATED_USER_PROFILE = Symbol('UPDATED_USER_PROFILE');
export const CHANGED_PASSWORD = Symbol('CHANGED_PASSWORD');
export const ADDED_INVENTORY = Symbol('ADDED_INVENTORY');
export const REMOVED_INVENTORY = Symbol('REMOVED_INVENTORY');
export const PROFILE_NEWSLETTER_SUBSCRIPTION = Symbol('PROFILE_NEWSLETTER_SUBSCRIPTION');

export function updateUserProfile(firstname, lastname, email, birthdate, childrenMode, subscribedToNewsletter) {
  const country = getCurrentCountry().toUpperCase();
  const newsletter = subscribedToNewsletter ?? false;

  return (dispatch) => {
    dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/user/update_profile',
        body: {
          first_name: firstname,
          last_name: lastname,
          email: email,
          birthdate: birthdate,
          children_mode: childrenMode,
          country: country,
          newsletter: newsletter,
        },
        successType: UPDATED_USER_PROFILE,
      },
    }).then((results) => {
      dispatch(setUserData(results[0].user));
    });
  };
}

export function changePassword(arg1, newPassword, isRecovery) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/user/change_password',
      body: {
        [isRecovery ? 'token' : 'password']: arg1,
        new_password: newPassword,
      },
      successType: CHANGED_PASSWORD,
    },
  };
}

export function askForPasswordRecovery(email) {
  const language = getCurrentLanguage();
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/password_recovery/recover_with_email',
      body: {
        email,
        language,
      },
      successType: ASKED_FOR_PASSWORD_RECOVERY,
    },
  };
}

export function addLibraryCard(libraryId, cardNumber, pinCode) {
  return (dispatch) => {
    dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/inventory/create_inventory',
        body: {
          card_number: cardNumber,
          pin_code: pinCode,
          library_id: libraryId,
        },
        successType: ADDED_INVENTORY,
      },
    }).then(() => {
      dispatch(fetchUserData());
      dispatch(closeModal());
    });
  };
}

export function removeLibraryCard(libraryId) {
  return (dispatch) => {
    dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/inventory/remove_inventory',
        body: {
          library_id: libraryId,
        },
        successType: REMOVED_INVENTORY,
      },
    }).then(() => {
      dispatch(fetchUserData());
    });
  };
}

export function checkSubscriberStatus() {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        method: 'get',
        path: '/subscriptions/subscriptions/check_subscriber_exists?include_status=true',
        successType: PROFILE_NEWSLETTER_SUBSCRIPTION,
      },
    })
      .then((response) => {
        const data = response[0];
        const exists = data.exists;
        const isActive = data.is_active;

        if (exists && !isActive) {
          dispatch(showNewsletterModal());
        }

        return data;
      })
      .catch((error) => {
        console.error('Error checking subscriber status:', error);
        return false;
      });
  };
}

export function softDeleteAccount() {
  const country_iso = getCurrentCountry();
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/user/user/soft_delete_account',
        body: {
          country_iso,
        },
        successType: SOFT_DELETED_ACCOUNT,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
